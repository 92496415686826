import Vue from 'vue'

export default {
    namespaced:true,

    state:{
        user:{},
        token:null
    },

    getters:{
        isLoggedIn: state => !!state.user.token,
        getUser: state => state.user,
        getToken: state => state.token
    },

    mutations:{
        AUTH_SUCCESS(state,data){
            state.user = data;
            state.token = data.token
        },
        LOGOUT(state){
            state.user = {};
            state.token = null
        }
    },

    actions:{
        login({commit},user){
            Vue.prototype.$api.defaults.headers.common['Authorization'] = 'Bearer '+user.token;
            commit('AUTH_SUCCESS',user);
        },
        logout({commit}){
            delete Vue.prototype.$api.defaults.headers.common.Authorization;
			commit('LOGOUT');
        }
    }
}