<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
            <CRow>
                <CCol col="10">
                    <router-link to="/pharmacies">Pharmacies</router-link> / {{isNew==true ? 'Add' : 'Edit'}}
                </CCol>
                <CCol col="2">
                    <CButton block color="success" class="btn-sm" @click="save()">Save</CButton>
                </CCol>
            </CRow>
        </CCardHeader>
        <CCardBody>
            <CRow>
                <CCol col="6">
                    <CInput label="Name" placeholder="Name" autocomplete="search" v-model="entity.name"/>
                </CCol>
                <CCol col="3">
                    <CInput label="Code" placeholder="Code" autocomplete="search" v-model="entity.code"/>
                </CCol>
                <CCol col="3">
                    <CInput label="Nsis" placeholder="Nsis" autocomplete="search" v-model="entity.nsis"/>
                </CCol>
            </CRow>
            <CRow>
                <CCol col="5">
                    <CInput label="City" placeholder="City" autocomplete="search" v-model="entity.city"/>
                </CCol>
                <CCol col="6">
                    <CInput label="Address" placeholder="Address" autocomplete="search" v-model="entity.address"/>
                </CCol>
                <CCol col="1">
                    <CInput label="Province" placeholder="Province" autocomplete="search" v-model="entity.province_code"/>
                </CCol>
            </CRow>
            <CRow>
                <CCol col="6">
                    <CInput label="VAT" placeholder="VAT" autocomplete="search" v-model="entity.vat_number"/>
                </CCol>
                <CCol col="6">
                    <p>Active</p>
                    <SwitchButton :trueValue="1" :falseValue="0" v-model="entity.active"/>
                </CCol>
            </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import SwitchButton from '../../components/basic/SwitchButton.vue'
export default {
  name: 'AddEditPharmacy',
  components:{
      SwitchButton
  },
  data () {
    return {   
        loaderData:false,
        entity: {},
        testValue:1,
        fields: [
            { key: 'name', label: 'Pharmacy', class: 'font-weight-bold' },
            { key: 'code', label: 'Code'},
            { key: 'city' , label: 'City'},
            { key: 'vat_number', label: 'VAT'},
            { key: 'nsis', label: 'Nsis'},
            { key: 'active', label: 'Active'}
        ],
        activePage: 1
    }
  },
  computed:{
      isNew(){
          return this.$route.params.id == "new";
      }
  },
  methods: {
      test(){
          alert('hello')
      },
    save(){
        if(this.isNew){
            this.$api.post('pharmacy',this.entity)
        }
        else{
            this.$api.put('pharmacy/'+this.entity.id_pharmacy,this.entity)
        }
    },
    loadPharmacy(){
      this.loaderData = true;
      this.$api.get('pharmacy/'+this.$route.params.id).then((res) => {
        if(res.data.success){
          this.entity = res.data.records
          this.loaderData = false;
        }
      })
    },
  },
  beforeMount(){
    this.loadPharmacy()
  }
}
</script>