<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <CRow>
                <CCol col="10">
                    Users
                </CCol>
                <CCol col="2" class="text-right">
                    <router-link to="/users/new" class="btn btn-success btn-sm">Add new</router-link>
                </CCol>
            </CRow>
        </CCardHeader>
        <CCardBody>
          <DataTableServer 
            :loader="loaderData" 
            :items="items" 
            :columns="fields" 
            @onChangePage="changePage">
            <template v-slot:actions="slotProps">
                <router-link :to="'users/'+slotProps.row.id">details</router-link>
            </template>
          </DataTableServer>
        </CCardBody>

      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import DataTableServer from '../../components/basic/DataTableServer.vue'
export default {
  name: 'Users',
  components:{
    DataTableServer  
  },
  data () {
    return {
      loaderData:false,
      items: {},
      fields: [
        { key: 'first_name', label: 'Name', class: 'font-weight-bold' },
        { key: 'created_at', label: 'Registered', format:(val)=>this.$moment(val).format('DD/MM/YYYY HH:mm:ss')},
        { key: 'role.name' , label: 'Role', format:(val)=>"<span class='badge badge-success'>"+val+"</span>"},
        { key: 'status.name', label: 'Status'}
      ],
      activePage: 1
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  methods: {
    changePage(val){
      this.loadUsers(val);
    },
    loadUsers(page=1){
      this.loaderData = true;
      this.$api.get('user?join=role,status,type&per-page=5&page='+page).then((res) => {
        if(res.data.success){
          this.items = res.data.records
          this.loaderData = false;
        }
      })
    },
    getBadge (status) {
      switch (status) {
        case 'Active': return 'success'
        case 'Inactive': return 'secondary'
        case 'Pending': return 'warning'
        case 'Banned': return 'danger'
        default: 'primary'
      }
    },
    rowClicked (item, index) {
      this.$router.push({path: `users/${index + 1}`})
    },
    pageChange (e,val) {
      for(let i=0;i<e.path[2].children.length;i++){
        e.path[2].children[i].classList.remove('active')
      }
      //e.path[1].classList.add('active')
      this.loadUsers(val);
      //this.$router.push({ query: { page: val }})
    }
  },

  beforeMount(){
    this.loadUsers()
  }
}
</script>
