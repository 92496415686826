<template>
<div>
  <CRow class="align-items-center" v-if="loader">
    <CCol class="align-items-center text-center">
      <CSpinner color="light" variant="grow"/>
    </CCol>
  </CRow>
  <template v-if="!loader">
      <template v-if="items.data.length>0">
      
        <table class="table table-striped table-hover">
          <thead>
            <th v-for="(c,i) in columns" :key="i">{{c.label}}</th>
            <th  v-if="$scopedSlots.actions"></th>
          </thead>
          <tbody>
            <tr v-for="(record, i) in items.data" :key="i">
              <td v-for="(column,i) in columns" :key="i" :class="column.class">
                <template v-if="'format' in column">
                  <span v-html="column.format(ObjectbyString(record, column.key))"></span>
                </template>
                <template v-else>
                  {{ObjectbyString(record, column.key)}}
                </template>
              </td>
              <td v-if="$scopedSlots.actions">
                <slot name="actions" v-bind:row="record"></slot>
              </td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="pagination">
            <ul class="pagination  justify-content-center">
              <li class="page-item" :class="{'disabled':items.prev_page==null}">
                <a @click="pageChange($event,items.first_page)" aria-label="Go to first page" aria-disabled="true" target="_self" tabindex="-1" class="page-link disabled">«</a>
              </li>
              <li class="page-item" :class="{'disabled':items.prev_page==null}">
                <a @click="pageChange($event,items.prev_page)" aria-label="Go to previous page" aria-disabled="true" target="_self" tabindex="-1" class="page-link disabled">‹</a>
              </li>
              <li v-for="n in numPageItems" :key="n" class="page-item" :class="{'active':items.current_page == n}" >
                <a @click="pageChange($event,n)" :aria-label="'Go to page'+n" target="_self" class="page-link c-page-link-number"> {{n}} </a>
              </li>
              <li class="page-item disabled" v-if="numPageItems<items.last_page">
                <a aria-label="..." target="_self" class="page-link">...</a>
              </li>
              <li class="page-item" :class="{'disabled':items.next_page==null}">
                <a @click="pageChange($event,items.next_page)" aria-label="Go to next page" target="_self" class="page-link">›</a>
              </li>
              <li class="page-item" :class="{'disabled':items.next_page==null}">
                <a @click="pageChange($event,items.last_page)" aria-label="Go to last page" target="_self" class="page-link">»</a>
              </li>
            </ul>
        </nav>
        <p>total: {{items.total}}</p>
      </template>
      <template v-else>
        Nothing to display yet.
      </template>
  </template>
</div>
</template>

<script>

export default {
  props: {
    loader:{ type:Boolean,default:true },
    items:{ type:Object,default() {return{data:[]}} },
    columns: Array,
  },

  data() {
    return {
      currentPage: 1,
      columnWidth: null,
      max_num_pagination_items:5
    };
  },
  computed:{
    numPageItems(){
      let array = [];
      let current_page = this.items.current_page;
      if(this.items.last_page>this.max_num_pagination_items){
        for(let i=current_page;i<=(this.max_num_pagination_items+current_page-1);i++){
          if(i<=this.items.last_page){
            array.push(i);
          }
        }
      }
      else{
        for(let i=1;i<=this.items.last_page;i++){
          array.push(i);
        }
      }
      return array;
    }
  },
  methods: {
    pageChange (e,val) {
      if(this.items.current_page!=val){
        for(let i=0;i<e.path[2].children.length;i++){
          e.path[2].children[i].classList.remove('active')
        }
        this.$emit('onChangePage',val)
      }
    },

    ObjectbyString(o, s) {
      s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
      s = s.replace(/^\./, ""); // strip a leading dot
      var a = s.split(".");
      for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
          o = o[k];
        } else {
          return;
        }
      }
      return o;
    },

    setColumnWidth() {
      if (window.innerWidth <= 600) {
        this.columnWidth = 100;
      } else {
        /*if(this.$scopedSlots.actions){
          this.columns.length = this.columns.length + 1
        }*/
        let percentage = this.$scopedSlots.actions ? 95 : 100;
        this.columnWidth = percentage / this.numColumns ;
        
      }
    },

    onRecordClick(record) {
      this.$emit("record-click", record);
    },
  },
};
</script>
<style>
.datatable {
  font-family: "Nunito", sans-serif;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
 
}

.datatable--row {
  border: 1px solid #dee2e6;
  display: block;
}

.datatable--column {
  text-align: left;
  display: inline-block;

}

.datatable--column__content {
  color: #5c7995;
  font-size: 14px;
}

@media screen and (max-width: 600px) {
  .datatable {
    border: 0;
  }
  .datatable--row {
    display: block;
  }

  .datatable--column {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
    margin-bottom: 25px;
  }

  .datatable--column::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

  .datatable--column:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }
  .datatable--column__desktop {
    display: none;
  }
}
</style>
