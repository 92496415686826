<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
            <CRow>
                <CCol col="10">
                    <router-link to="/users">Users</router-link> / {{isNew==true ? 'Add' : 'Edit'}}
                </CCol>
                <CCol col="2">
                    <CButton block color="success" class="btn-sm" @click="save()">Save</CButton>
                </CCol>
            </CRow>
        </CCardHeader>
        <CCardBody>
            <CRow>
                <CCol col="6">
                    <CInput label="Name" placeholder="Name" autocomplete="search" v-model="entity.first_name"/>
                </CCol>
                <CCol col="6">
                    <CInput label="Surname" placeholder="Surname" autocomplete="search" v-model="entity.last_name"/>
                </CCol>
            </CRow>
            <CRow>
                <CCol col="6">
                    <CInput label="Email" placeholder="Email" autocomplete="search" v-model="entity.email"/>
                </CCol>
                <CCol col="6">
                    <CInput type="password" label="Password(leave empty if don't want to change)" placeholder="Password" autocomplete="search" v-model="entity.password"/>
                </CCol>
            </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import SwitchButton from '../../components/basic/SwitchButton.vue'
export default {
  name: 'AddEditPharmacy',
  components:{
      SwitchButton
  },
  data () {
    return {   
        loaderData:false,
        entity: {},
    }
  },
  computed:{
      isNew(){
          return this.$route.params.id == "new";
      }
  },
  methods: {
    save(){
        if(this.isNew){
            this.$api.post('user',this.entity)
        }
        else{
            this.$api.put('user/'+this.entity.id_user,this.entity)
        }
    },
    loadUser(){
      this.loaderData = true;
      this.$api.get('user/'+this.$route.params.id).then((res) => {
        if(res.data.success){
          this.entity = res.data.records
          this.entity.password = null
          this.loaderData = false;
        }
      })
    },
  },
  beforeMount(){
    this.loadUser()
  }
}
</script>