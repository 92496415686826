<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          Stocks
        </CCardHeader>
        <CCardBody>
            <CRow>
                <CCol col="3">
                    <CInput placeholder="Search" autocomplete="search" v-model="search"/>
                </CCol>
                <CCol col="2">
                    <CButton block color="primary" @click="loadStocks(1,search)">Search</CButton>
                </CCol>
            </CRow>
            <DataTableServer 
            :loader="loaderData" 
            :items="items" 
            :columns="fields" 
            @onChangePage="changePage">
            </DataTableServer>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import DataTableServer from '../../components/basic/DataTableServer.vue'
export default {
  name: 'Stocks',
  components:{
    DataTableServer  
  },
  data () {
    return {
        search:"",    
        loaderData:false,
        items: {},
        fields: [
            { key: 'pharmacy', label:'Pharmacy', format:(val)=>val.name+' <br><small>'+val.address+' - '+val.city+'('+val.province_code+')</small>'},
            { key: 'minsan', label: 'Minsan'},
            { key: 'stock', label: 'Stock'},
            { key: 'qnt_used', label: 'Qnt Used'},
            { key: 'arriving_gr', label: 'Arr GR'},
            { key: 'arriving_dt', label: 'Arr DT'},
            { key: 'rotation_index', label: 'Rot Index'},
            { key: 'last_price', label: 'Last Price', format:(val)=>{if(val!=null){return '€ '+val}}},
            { key: 'date_last_buy', label: 'Last Buy', format:(val)=>{if(val!=null){return this.$moment(val).format('DD/MM/YYYY')}}},
            { key: 'last_update', label: 'Last Update', format:(val)=>this.$moment(val).format('DD/MM/YYYY')}
        ],
        activePage: 1
    }
  },
  methods: {
    changePage(val){
      this.loadStocks(val);
    },
    loadStocks(page=1){
      this.loaderData = true;
      this.$api.get('stock?join=pharmacy&per-page=10&page='+page+'&search='+this.search+'=[minsan]').then((res) => {
        if(res.data.success){
          this.items = res.data.records
          this.loaderData = false;
        }
      })
    },
  },
  beforeMount(){
    this.loadStocks()
  }
}
</script>