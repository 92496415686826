<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
            <CRow>
                <CCol col="10">
                    Pharmacies
                </CCol>
                <CCol col="2" class="text-right">
                    <router-link to="/pharmacies/new" class="btn btn-success btn-sm">Add new</router-link>
                </CCol>
            </CRow>
        </CCardHeader>
        <CCardBody>
            <CRow>
                <CCol col="3">
                    <CInput placeholder="Search" autocomplete="search" v-model="search"/>
                </CCol>
                <CCol col="2">
                    <CButton block color="primary" @click="loadPharmacies(1,search)">Search</CButton>
                </CCol>
            </CRow>
            <DataTableServer 
            :loader="loaderData" 
            :items="items" 
            :columns="fields" 
            @onChangePage="changePage">
            <template v-slot:actions="slotProps">
                <router-link :to="'pharmacies/'+slotProps.row.id">details</router-link>
            </template>
            </DataTableServer>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import DataTableServer from '../../components/basic/DataTableServer.vue'
export default {
  name: 'Stocks',
  components:{
    DataTableServer  
  },
  data () {
    return {
        search:"",    
        loaderData:false,
        items: {},
        fields: [
            { key: 'name', label: 'Pharmacy', class: 'font-weight-bold' },
            { key: 'code', label: 'Code'},
            { key: 'city' , label: 'City'},
            { key: 'vat_number', label: 'VAT'},
            { key: 'nsis', label: 'Nsis'},
            { key: 'active', label: 'Active', format:(val)=>{
                if(val==1){
                    return "<span class='badge badge-success'>&nbsp;&nbsp;</span>"
                }
                else{
                    return "<span class='badge badge-danger'>&nbsp;&nbsp;</span>"
                }
            }}
        ],
        activePage: 1
    }
  },
  methods: {
    changePage(val){
      this.loadPharmacies(val);
    },
    loadPharmacies(page=1){
      this.loaderData = true;
      this.$api.get('pharmacy?per-page=10&page='+page+'&search='+this.search+'=[name,vat_number,nsis]').then((res) => {
        if(res.data.success){
          this.items = res.data.records
          this.loaderData = false;
        }
      })
    },
  },
  beforeMount(){
    this.loadPharmacies()
  }
}
</script>