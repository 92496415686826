import Container from "@/containers/TheContainer";
import Login from "@/views/Login";
import Dashboard from "@/views/Dashboard";
import Charts from "@/views/charts/Charts";
import Users from "@/views/users/Users";
import Stocks from "@/views/stocks/Stocks";
import Pharmacies from "@/views/pharmacies/Pharmacies";
import AddEditPharmacy from "@/views/pharmacies/AddEditPharmacy";
import AddEditUser from "@/views/users/AddEditUser";

const routes = [
    {
        path:"/",
        component: { render (c) { return c('router-view') } },
        redirect: "/login",
        children:[
            {
                path: "login",
                name: "login",
                component: Login,
                meta:{
                    requiresAuth:false
                }
            }
        ]
    },
    {
        path: "/",
		component: Container,
		redirect: "/dashboard",
        children:[
            {
				path: "dashboard",
				name: "Dashboard",
				component: Dashboard,
				meta: {
					requiresAuth:true
				}
			},
            {
                path: "users",
                name: "Users",
                component: Users,
                meta:{
                    requiresAuth:true
                }
            },
            {
                path: "users/:id",
                name: "Users",
                component: AddEditUser,
                meta:{
                    requiresAuth:true
                }
            },
            {
                path: "stocks",
                name: "Stocks",
                component: Stocks,
                meta:{
                    requiresAuth:true
                }
            },
            {
                path: "pharmacies",
                name: "Pharmacies",
                component: Pharmacies,
                meta:{
                    requiresAuth:true
                }
            },
            {
                path: "pharmacies/:id",
                name: "Pharmacies",
                component: AddEditPharmacy,
                meta:{
                    requiresAuth:true
                }
            },
            {
                path: 'charts',
                name: 'Charts',
                component: Charts,
                meta: {
                    requiresAuth:true
                }
            }
        ]
    }

];

export default routes;