<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://coreui.io" target="_blank">Mypharma</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} devLab.</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Developed with &hearts; by</span>
      <a href="https://robdap93.net" target="_blank">robdap93</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
