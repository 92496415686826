import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import store from '../store'

Vue.use(Router);

// configure router
const router = new Router({
	mode:'hash',
  	routes: routes,
  	linkActiveClass: 'active',
  	scrollBehavior: () => ({ y: 0 })
});

router.beforeEach((to, from, next) => {
	if(to.meta.requiresAuth){
		const token = store.getters['session/getToken'];
		console.log(token)
		Vue.prototype.$api.get('auth/check-token/'+btoa(token)).then((res) => {
			if(res.data.success){
				next();
			}
			else{
				store.dispatch('session/logout');
				next('login');
			}
		});
	}
	else{
		if(store.getters['session/isLoggedIn']){
			next('dashboard');
		}
		else{
			next();
		}
	}
});

export default router;