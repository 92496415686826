<template>
    <div>
        <p>{{label}}</p>
        <CSwitch
            class="mr-1"
            color="success"
            :checked.sync="data_comp"
            shape="pill"
            @update:checked="save($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'SwitchButton',
    props: ['label','trueValue','falseValue','value'],
    data(){
        return{
            data_value:true
        }
    },
    computed:{
        data_comp:{
            get(){
                if(this.value == this.trueValue){
                    return true;
                }
                if(this.value == this.falseValue){
                    return false;
                }
            },
            set(val){

            }
        }
    },
    methods: {
        save(val){
            if(val==true){
                this.$emit('input',this.trueValue)
            }
            if(val==false){
                this.$emit('input',this.falseValue)
            }
        }
    }
}
</script>