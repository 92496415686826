import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import template from './template.js'
import session from './session.js'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState()],
    modules:{
        template:template,
        session:session
    }
})