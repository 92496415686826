import axios from 'axios';
import store from '../store';
/*
import moment from 'moment';
import izitoast from './toast';*/

export const API = axios.create({
	baseURL:process.env.VUE_APP_BASE_URL_API,
	/*headers:{
		Authorization: "Bearer ZXlKMGVYQWlPaUpLVjFRaUxDSmhiR2NpT2lKSVV6STFOaUo5LmV5SnBjM01pT2lKd2FXeHNMV0Z3YVNJc0luTjFZaUk2ZXlKcFpGOTFjMlZ5SWpveExDSmxiV0ZwYkNJNkluSnZZbVJoY0RrelFHRmtiV2x1TG1SbGRpSXNJbVpwY25OMFgyNWhiV1VpT2lKU2IySmxjblJ2SWl3aWJHRnpkRjl1WVcxbElqb2lSQ2RCY0c5c2FYUnZJaXdpYVdSZmRYTmxjbDkwZVhCbElqb3hNQ3dpYVdSZmRYTmxjbDl6ZEdGMGRYTWlPalV3TENKcFpGOXliMnhsSWpveE1Dd2lhV1FpT2lJMFpqQmlZak0xWlMxbU1tVXdMVEV4WldJdE9Ea3pZUzB6Tm1Nd01qUTNNR00yT1RJaWZTd2lhV0YwSWpveE5qTTBOak01TkRjekxDSmxlSEFpT2pFMk16UTJOVEF5TnpOOS5KRGl0SDJVMWUyTVQ4UlB3VkhVMWhsMHdxMWVVU2lReFR4ZWpsQ2dHWDRz"
	}*/
});

API.interceptors.response.use((response) => 
	{
		return response
	},
	(error) => {
		const status = error.response.status;
		if(status == 422){
			if(error.response.data.message == 'DATA_NOT_VALID'){
				let message = "";
				error.response.data.records.forEach(m => {
					message += m+"\n";
				});
				alert(JSON.stringify(error.response.data.records[0]));
			}
		}
		if(status == 400){
			alert(error.response.data.message)
		}
		return Promise.reject(error);
	}
)

API.interceptors.request.use(function (config) {
    const token = store.getters['session/getToken'];
    config.headers.Authorization = 'Bearer '+token;
    return config;
});

/*API.interceptors.request.use (
	function (config) {
		const token = store.getters['session/getToken'];
		//if (api_key!==null && store.getters.loginExpire>moment().format('YYYY-MM-DD HH:mm:ss')) 
			config.headers.Authorization = `Bearer ${token}`;
		//else
			//delete config.headers.Authorization
		//return config;
	},
	function (error) {
		return Promise.reject (error);
	}
);
*/
export default API;